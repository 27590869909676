<template>
  <div class="main">
<!--    <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/warning/maturity.png" alt="">-->
    <div class="text">该店铺已经到期啦！</div>
  </div>
</template>

<script>
  // 店铺到期
  export default {
    name: 'maturity',
    created () {
      this.$emit('showheader', false)
    }
  }
</script>

<style scoped lang="scss">
  .main{
    height: calc(100vh - 360px);
    min-height: 512px;
    width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;

    img{
      width: 100px;
      height: 100px;
      margin-bottom: 13px;
    }

    .text{
      font-size: 15px;
      color: #999999;
      font-weight: 500;
    }
  }
</style>
